<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Акции и скидки</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-2">
      <v-col cols="10">
        <v-text-field v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      label="Поиск"
                      dense
                      outlined
                      rounded
                      hide-details/>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" rounded @click="create" dark color="primary">
          <span class="font-weight-bold hidden-xs-only">Создать</span>
          <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="hidden-xs-only">
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Oт нас/От зав.</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Название</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Процент</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption pr-8">Время</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Заведение</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Блюдо</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Категория</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Редактировать</span>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.xs ? 'pa-2' : 'py-1'" no-gutters class="ma-1 grey lighten-4" v-for="disc in filteredList" :key="disc.id">
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Oт нас/От зав.:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <v-tooltip top>
          <template v-slot:activator="{attrs, on}">
            <v-chip v-bind="attrs" v-on="on" small color="primary" v-if="disc.our === true" dark>от Нас</v-chip>
            <v-chip v-bind="attrs" v-on="on" small color="blue" v-else dark>от Заведения</v-chip>
          </template>
          <span>{{ disc.description }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="2" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Название:</span>
      </v-col>
      <v-col cols="10" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : 'text-center'" class="text-body-2">{{ disc.title }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Процент:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ disc.amount }} %</span>
      </v-col>
      <v-col cols="12" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters d-flex align-center">
        <v-col cols="4" class="row no-gutters hidden-sm-and-up">
          <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Начало:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters justify-end justify-lg-start justify-md-start justify-sm-start caption">
          <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2 text-no-wrap">{{ disc.started_at | dateFormat }} {{ disc.begin_at }}</span>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up">
          <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Конец:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters justify-end justify-lg-start justify-md-start justify-sm-start caption">
          <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2 text-no-wrap">{{ disc.ended_at | dateFormat }} {{ disc.finish_at }}</span>
        </v-col>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Заведение:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" v-if="!disc.store.name" class="text-body-2">-</span>
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : 'text-center'" v-else class="text-body-2">{{ disc.store.name }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Блюдо:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" v-if="!disc.item.name" class="text-body-2">-</span>
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : 'text-center'" v-else class="text-body-2">{{ disc.item.name }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Категория:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" v-if="!disc.category.name" class="text-body-2">-</span>
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : 'text-center'" v-else class="text-body-2">{{ disc.category.name }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1 d-flex align-center' : ''" class="caption">Редактировать:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around d-flex align-center">
        <v-btn :class="$vuetify.breakpoint.xs ? 'mr-3' : ''" fab x-small @click="edit(disc)">
          <v-icon color="primary">mdi-pencil</v-icon>
        </v-btn>
        <v-btn fab x-small @click="del(disc)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {uuid} from "uuidv4";
import format from 'date-fns/format';
import axios from "../../services/axios";

export default {
  data() {
    return {
      search: "",
      list: []
    }
  },

  beforeMount() {
    this.fetch();
  },

  computed: {
    filteredList() {
      if (!this.search) {
        return this.list;
      }
      return this.list.filter(row => row.title.toLowerCase().includes(this.search));
    }
  },

  methods: {
    fetch() {
      axios.send({
        url: "/discounts",
        method: "GET"
      }).then(resp => {
        this.list = resp.data;
      })
    },

    create() {
      this.$router.push({name: "editDiscounts", params: {id: uuid.Nil}});
    },

    edit(disc) {
      this.$router.push({name: "editDiscounts", params: {id: disc.id}});
    },

    del(disc) {
      this.$confirm(
          "Вы точно хотите удалить скидку",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/discounts",
          method: "DELETE",
          data: disc
        }).then(() => {
          this.fetch();
        })
      })
    }
  },
  filters: {
    dateFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    },
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd HH:mm")
    }
  }
}
</script>
